<template>
	<div id="about" class="about" ref="about">
		<!-- 头部 nav导航 -->
		<header-nav :tab="true"></header-nav>
		<!-- 关于我们-->
		<div class="content-box">
			<!-- 小屏 -->
			<div class="about-logo" v-if="is_vertical">
				<img src="/images/about-bg.png" style="width: 100%;height: 100%;"/>
			</div>
			<div class="about-logo-top">
				<div class="about-logo2">
					<img src="/images/about-bg2.png" style="width: 100%;height: 100%;"/>
				</div>
				<div class="t1">千雪 · 起源</div>
				<div class="t2">“每一个存在都有问鼎的机会，就看如何 选择，如何把握”</div>
				<div class="t3">我们始终认为，做任何事情的成功与否，不在于事情本身是否可行，而在于做事的人是否可行。再大的公司再大的工程，也是一个执行团队的力量而做的，所以，我们对每一个工程都会严格抓质量、抓效率、抓责任做起，从始而行。</div>
				<div class="about-logo">
					<img :src="aboutInfo.thumbnail" style="width: 100%;height: 100%;"/>
				</div>
				
				<div class="c1" style="margin-top: 30px;">About us</div>
				<div class="p1">关于我们</div>
				<div class="blue-line"></div>
				<div class="t3" v-html="aboutInfo.content"></div>
			
				<div class="about-logo4" style="margin: 30px 0;">
					<img src="/images/about-bg4.png" style="width: 100%;height: 100%;"/>
				</div>
				
				<div class="t4">宁德地区（包括福安、霞浦、周宁等地）均可上门服务。</div>
				<div class="t4">联系电话：13616088271</div>
			</div>
		</div>
		
		<!-- 底部链接 -->
		<!-- 友情链接 -->
		<section class="friend uni-flex-column uni-flex-center" style="margin-top: 30px;">
			<div class="friend-content">
				<div class="friend-text">
					<span>友情链接：</span>
					<a href="https://shop117115684.taobao.com/?spm=a2142.8996326.sellercard.15.146f2be3VrqbMR">千雪制冷淘宝店</a>
				</div>
			</div>
		</section>
		<!-- 联系我们 -->
		<!-- 小屏 -->
		<section class="foot uni-flex-column uni-flex-center" ref="contact" v-if="is_vertical">
			<div class="foot-content">
				<div class="foot-logo">
					<img src="/images/qrcode.png" style="width: 100%;height: 100%;"/>
				</div>
				<div class="foot-scan">微信扫一扫</div>
				<div class="foot-text">
					<span>copyright © 2020-2021 ALL RIGHT RESERVED 宁德市千雪制冷设备有限公司 版权所有</span>
					<span>地址：宁德市蕉城区古溪新村23号</span>
					<span>备案号：闽ICP备20014190号</span>
					<span>电话：13616088271</span>
				</div>
			</div>
		</section>
		<!-- 大屏 -->
		<section class="foot uni-flex-column uni-flex-center" ref="contact" v-else>
			<div class="foot-content">
				<div class="foot-text">
					<span>copyright © 2020-2021 ALL RIGHT RESERVED 宁德市千雪制冷设备有限公司 版权所有</span>
					<span>地址：宁德市蕉城区古溪新村23号</span>
					<span>备案号：闽ICP备20014190号</span>
					<span>电话：13616088271</span>
				</div>
				<div class="foot-logo">
					<img src="/images/qrcode.png" style="width: 100%;height: 100%;"/>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import api from '@/utils/api.js';
	import headerNav from '@/components/header.vue';
	export default {
		name: 'home', // 首页
		components: {
			headerNav,
		},
		data() {
			return {
				is_vertical: false,  //false 小屏  true 大屏
				win_width: document.body.clientWidth,
				id: '',
				search: {
					categoryId: 1,
					current: 1,
					size: 1,
				},
				aboutInfo: {},
			}
		},

		computed: {
			
		},
		created() {
			window.scrollTo({
			    left: 0,
			    top: 0,
			    behavior: 'smooth'
			});
			
			if(this.$route.query.id) {
				this.id = this.$route.query.id
				this.api_detailInfo(this.id)
			}
			else {
				this.search.current = 1;
				this.api_menuList();
			}
		},
		mounted() {
			/* 获取页面高宽 */
			// this.body_width = document.getElementById('about').offsetWidth;
			// this.body_height = document.getElementById('about').clientHeight;
			const that = this;
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth;
					that.win_width = window.screenWidth;
				})();
			};
			console.log(this.win_width);
			if (this.win_width < 768) {
				//小屏
				this.is_vertical = true;
			} else if (this.win_width >= 768) {
				//大屏
				this.is_vertical = false;
			}
		},
		watch: {
		},
		methods: {
			api_detailInfo(id) {
				api.newsDetail(id).then(res=>{
					console.log(res);
					if(res.code == 0){
						this.aboutInfo = res.data.article;
					}
				})
			},
			api_menuList() {
				api.menuList().then(res=>{
					console.log(res);
					if(res.code == 0){
						res.data.forEach(item => {
							if(item.title == "关于我们") {
								this.search.categoryId = item.id;
								this.api_aboutus();
							}
						});
					}
				})
			},
			api_aboutus() {
				api.newsList(this.search.categoryId,this.search).then(res=>{
					console.log(res);
					if(res.code == 0){
						if(res.data.records.length>0) {
							this.api_detailInfo(res.data.records[0].id)
						}
						else {
							this.aboutInfo = {
								title: '以创新的技术，产品与设计重塑人与环境，使生活更美',
								thumbnail: '/images/about-bg3.png',
								content: '宁德市千雪制冷设备有限公司是一家集冷库、冷柜、风幕柜、保鲜库、冷冻库、各种制冰机、工业冷水机、制冷半封闭压缩机、中央空调等各种制冷设备设计、维修、安装的公司，拥有十几年的制冷经验，本着诚实、诚信、服务至上的原则服务了多家大、中、小型公司以及个体客户。 可承建成不同大小规模的冷库。我中心工程部按冷库的面积、温度及不同的使用需要给予全理化配置设计安装。以最先进的设计方案为客户降低成本投资，多品种，多规格的库板也可根据您的需要定制特殊的异型冷库，确保库的精度。外型美观为用户充分利用现有场地和建筑空间提供方便并可满足不同温度的需求。 在自身发展的过程中，本公司始终本着以人为本，以科技促发展的经营理念。'
							}
						}
					}
				})
			},
		}
	}
</script>

<style scoped="" lang="less">
	.yx-h5 {
		font-size: 14px;
	}

	.about {
		height: 100%;
		overflow: auto;
		font-size: 20px;
		color: #bdbdbd;
		background-color: #FFFFFF;
	}
	
	.content-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		.about-logo {
			width: 100%;
			height: 900px;
		}
		
		.about-logo-top {
			width: 1200px;
			margin: 50px 15px;
			text-align: left;
			.about-logo2 {
				width: 300px;
				height: 200px;
			}
			.about-logo4 {
				width: 100%;
				height: 800px;
			}
			
			.t1 {
				margin-top: 30px;
				font-size: 36px;
				font-family: Source Han Sans CN;
				font-weight: bold;
				color: #111111;
				line-height: 80px;
			}
			.t2 {
				font-size: 30px;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #111111;
				line-height: 50px;
			}
			.t3 {
				margin: 10px 0;
				font-size: 20px;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #111111;
				line-height: 30px;
			}
			.t4 {
				margin: 10px 0;
				font-size: 18px;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #111111;
			}
			
			.c1 {
				color: #0E82D9;
				font-size: 30px;
				font-family: Montserrat;
				font-weight: bold;
				line-height: 40px;
			}
			
			.p1 {
				font-size: 35px;
				font-family: Montserrat;
				font-weight: bold;
				color: #000000;
				line-height: 50px;
			}
			
			.blue-line {
				margin: 15px 0;
				width: 100px;
				height: 4px;
				background: #0E82D9;
			}
		}
	}

	.friend {
		margin-top: 30px;
		height: 50px;
		background-color: #F5F5F5;
		
		.friend-content {
			display: flex;
			flex-direction: wrap;
			width: 1200px;
			
			.friend-text {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 24px;
			}
		}
	}
	
	.foot {
		height: 200px;
		background-color: #0E82D9;
		
		.foot-content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 1200px;
			
			.foot-text {
				display: flex;
				flex-direction: column;
				text-align: left;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 24px;
				.span {
				}
			}
			
			.foot-logo {
				width: 142px;
				height: 142px;
			}
		}
	}

	/* 手机端 */
	@media (orientation:portrait) and (max-width:890px) {
		
		.about {
			overflow-y: auto;
			overflow-x: hidden;
		}
		
		.content-box {
			width: 100%;
			.about-logo {
				width: 100%;
				height: 300px !important;
			}
			
			.about-logo-top {
				width: 90%;
				margin: 30px 0;
				text-align: left;
				.about-logo2 {
					width: 150px;
					height: 100px;
				}
				.about-logo4 {
					width: 100%;
					height: 300px;
				}
				.t1 {
					margin-top: 30px;
					font-size: 30px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #111111;
					line-height: 50px;
				}
				.t2 {
					font-size: 20px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #111111;
					line-height: 30px;
				}
				.t3 {
					margin: 10px 0;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #111111;
					line-height: 24px;
				}
				.t4 {
					margin: 10px 0;
					font-size: 12px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #111111;
				}
				
				.c1 {
					color: #0E82D9;
					font-size: 20px;
					font-family: Montserrat;
					font-weight: bold;
					line-height: 30px;
				}
				
				.p1 {
					font-size: 24px;
					font-family: Montserrat;
					font-weight: bold;
					color: #000000;
					line-height: 40px;
				}
				
				.blue-line {
					margin: 15px 0;
					width: 50px;
					height: 2px;
					background: #0E82D9;
				}
			}
		}
		
		.friend {
			margin-top: 30px;
			height: 30px;
			background-color: #F5F5F5;
			
			.friend-content {
				display: flex;
				flex-direction: wrap;
				width: 100%;
				margin-left: 10px;
				.friend-text {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					line-height: 20px;
				}
			}
		}
			
		.foot {
			// margin-top: 30px;
			height: 370px;
			background-color: #0E82D9;
			
			.foot-content {
				display: flex;
				flex-direction: column;
				width: 100%;
				.foot-scan {
					padding: 20px 0;
					text-align: center;
					font-size: 12px;
					color: #FFFFFF;
				}
				.foot-text {
					margin-top: 20px;
					text-align: center;
					font-size: 12px;
				}
				
				.foot-logo {
					width: 120px;
					height: 120px;
				}
			}
		}

		.header .content .nav {
			display: none !important;
		}

		.content {
			justify-content: space-between !important;
			padding: 0 20px;

			.clear {
				display: none;
			}

			.nav-min {
				display: flex !important;
			}

			.langs {
				display: none;
			}
		}
	}
</style>
